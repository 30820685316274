(function($) {
	'use strict'; 
	window.mnidwebsite = {
		cache: {
			$document: $(document),
			$window: $(window)
		}, 
		init: function() {
			this.bindEvents();
		},
		bindEvents: function() { 
			var self = this;

			$(window).ready(function() {
				if($('body').hasClass('isdesktop')){
					
				}
				self.initMenus();
				self.initCarousels();
				
				$(window).resize(function() {
					self.handleResize();
				});
				self.handleResize();
				 
			});
		}, 
		handleResize: function() {
			var window_width = $(window).width();
			if(window_width > 991){
				$('#filterlist .btn').removeClass('btn-sm').addClass('btn-lg');
			}
			else{
				$('#filterlist .btn').removeClass('btn-lg').addClass('btn-sm');
			}
		},
		initMenus: function(){
			
			var myPlayer = jQuery(".player").vimeo_player();
			
			myPlayer.on("VPStart VPFallback", function(){
                /**
                 * VPStart:
                 * Hide the loader once the player start
                 */
                $(".videoloader").fadeOut(1000);

            });
			
			$('.nav-link').on('click',function(e){
				$('.nav-item').removeClass('current');
				$(this).parent('.nav-item').addClass('current');
			});
			$('.contactlink').on('click',function(e){
				var page = $(this).attr('href'); // Page cible
				var speed = 750; // Durée de l'animation (en ms)
				var theOffset = $(page).offset().top - 100;
				$('html, body').animate( { scrollTop: theOffset }, speed ); // Go
				e.preventDefault();
				return false;
			});
			
			$('[data-toggle="tooltip"]').tooltip();
			
			var $nav = $(".navbar");
			$(document).scroll(function () {
				if( !$('body').hasClass('page-template-template_mentions') ){
					$nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
				}
			});
			if( $('body').hasClass('page-template-template_mentions') ){
				$nav.addClass('scrolled');
			}
			
			
			
			
			$("#contactform").validate({
				rules: {
					"field_email": {
						required: true,
						email: true
					},
					"field_name": {
						required: true,
					},

					"field_company": {
						required: false,
					},
					"field_message": {
						required: true,
					},
					"field_captcha":{
						required:true,
						captcha:true
					}
					
				},
				messages: {
					"field_captcha": {
						required: "Obligatoire",
					}
				},
				submitHandler: function(myform, event) {
					
					
					
					event.preventDefault();
					var data = $(myform).serialize();
					
					console.log(data);
					$.post("/wp-content/themes/mnid/inc/sendmail.php", data, function(data) {
						
					}).done(function(datareturn) {
						console.log(datareturn);
						
						var thanksmessage = "<div class='alert alert-success' role='alert'>Votre message a bien été envoyé, merci et à très bientôt !</div>";
						var spammessage = "<div class='alert alert-warning' role='alert'>Spam détecté !</div>";
						var errmessage = "<div class='alert alert-error' role='alert'>Oups, une erreur est survenue ! Merci de réessayer ultérieurement.</div>";
						
						if (datareturn === "sent") {
							$('#contactform_feedback').html(thanksmessage);
						}
						else if (datareturn === "spam") {
							$('#contactform_feedback').html(spammessage);
						}
						else{
							$('#contactform_feedback').html(errmessage);
						}
						if(datareturn!="errpost"){
							$(myform).trigger('reset');
						}
					});
				}
			});
			
			$('#filtergrid').isotope({
				layoutMode: 'masonry',
				masonry: {
					columnWidth: '.grid-sizer',
					//isFitWidth: true
				}

			});
			var filters = [];
			$('.filter').on('change',function(e){
				var $grid = $('#filtergrid');
				var isoFilters = [];
				var filterstring = "";
				//console.log($(e).attr('data-filtername'));
				if( $(this).hasClass('viewall')) {
					
					var filterstring = "*";
				}
				else {
					
					var elems = $('.filter:checked');
					$.each(elems, function(i, e){
						if( $(e).data('filtername') != "*") {
							isoFilters.push('.'+$(this).data('filtername'));
						} 
					});
				
					
					var filterstring = isoFilters.join(', ');
					console.log(filterstring);
					
				}
				$grid.isotope({ filter: filterstring });
				
				if(!$(this).hasClass('viewall')){
					$('.filter.viewall').parent().removeClass('active');
					$('.filter.viewall').parent().find('i').removeClass('typcn-times').addClass('typcn-chevron-right');
				}
				else{
					$('.filter').not('.viewall').parent().removeClass('active');
					$('.filter').not('.viewall').parent().find('i').removeClass('typcn-times').addClass('typcn-chevron-right');
				}
				
				if($(this).prop('checked')){
					//console.log('filter was checked');
					$(this).parent().find('i').removeClass('typcn-chevron-right').addClass('typcn-times');
					
				}
				else{
					//console.log('filter was unchecked');
					$(this).parent().find('i').removeClass('typcn-times').addClass('typcn-chevron-right');
					
				}
				
			});
		},
		initCarousels: function(){
			var swiper_home_team = new Swiper('#swiper_team', {
			    speed: 400,
			    navigation: {
				    nextEl: '.swiper_team_next',
				    prevEl: '.swiper_team_prev',
				},
				autoplay: {
			    	delay: 4000,
			  	},
			    //spaceBetween: 100
			});
			var swiper_home_testimonials = new Swiper('#swiper_testimonials', {
			    speed: 400,
			    navigation: {
				    nextEl: '.swiper_testimonials_next',
				    prevEl: '.swiper_testimonials_next',
				},
				autoplay: {
				    delay: 6000,
				  },

			});
			var swiper_home_clients = new Swiper('#swiper_clients', {
			    speed: 400,
			    navigation: {
				    nextEl: '.swiper_clients_next',
				    prevEl: '.swiper_clients_prev',
				},

			});
			console.log('carousels init');
		},
	}
	mnidwebsite.init();
})(jQuery);